import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

import HeroImage from "../images/cruisin.jpg"
import SecondaryImage from "../images/DemoRequest-image-03.jpg"
import DemoForm from "../components/demoForm"

export default function() {
  return (
    <Layout>
      <SEO title={"Powersports Support Demo Request"} description={"Call or Email us to schedule a quick 15-minute demo of PSS."}/>
      <Hero image={HeroImage} backgroundPosition={"50% center"} justifyEnd={false} minHeight={"20vh"}>
        <div className={"col col-lg-6 pl-lg-5 align-self-center text-white"}>
          <h1
            style={{
              fontFamily: "Anton",
              fontWeight: 400,
              fontSize: "4rem",
            }}
          >
            SCHEDULE<br/> A TEST RIDE
          </h1>
        </div>
      </Hero>

      <div className={"row no-gutters mb-5"}>
        <div className={"d-none d-md-block col-md-6 align-self-center text-right"}>
          <img src={SecondaryImage} alt={"Motorcycle flying through the air"} className={"img-fluid"}/>
        </div>
        <div className={"col-md-6 align-self-center ml-md-n5"}>
          <DemoForm/>
        </div>
      </div>
    </Layout>
  )
}
