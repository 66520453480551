import React from "react"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"
import Helmet from "react-helmet"

export default class DemoForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script src="https://embed.typeform.com/next/embed.js"/>
        </Helmet>
        <div className={"row mb-3"}>
          <div className={"col border p-3 bg-white shadow mx-md-5 mx-md-0"}>
            <h1
              style={{
                fontFamily: "Anton",
                fontWeight: 400,
                fontSize: "4rem",
              }}
            >
              DEMO REQUEST
            </h1>
            <p className={"lead"}>Our strategic partnerships provide you with all the products and access you need to grow your business. Please fill out the form below and someone will be in contact within 24-48 hours.</p>
            <p className={"lead"}>
              Feel free to call us any time: <a href="tel:+18006334374" className={"text-secondary"}>+1 (800) 633-4374</a>.
            </p>

            <div
                data-tf-widget="dr75Z2Tv"
                data-tf-opacity="100"
                data-tf-iframe-props="title=PSS Demo Request Form"
                data-tf-medium="snippet"
                style={{
                  width: '100%',
                  height: '500px'
                }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
